<template>
  <div class="withdrawalDetails">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source />
      <div class="navTitle">提现明细</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :isNoData="isNoData"
        :error="error"
      >
        <div class="listItem" v-for="item in list" :key="item.id">
          <div class="orderNo">
            账单编号: {{ item.id }}
            <div class="copyBtn" @click="copyText(item.id)">复制</div>
          </div>
          <div class="orderSta">
            {{ handWithdrawalType(item.payType) }}-{{
              handleOrderSta(item.status)
            }}
            <div class="reasonBtn" @click="openReason(item.statusDesc)" v-if="item.status == 3">查看原因</div>
          </div>
          <!-- <div class="amount">{{ item.amount / 10 }}金豆</div> -->

          <div class="orderDate">
            {{ item.createdAt | orderDate }}
            <div class="amountText">{{ item.amount / 10 }}元</div>
          </div>
        </div>
      </PullRefresh>
    </div>
    <van-popup v-model="showReason">
      <div class="popupBox">
        <div class="popupTitle">原因</div>
        <div class="line"></div>
        <div class="popupContent">{{ reasonText }}</div>
        <div class="confirm" @click="showReason = false">确定</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import { queryWithdrawDetails } from "@/api/mine";
import PullRefresh from "@/components/PullRefresh";
export default {
  components: {
    PullRefresh,
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 10,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
      showReason: false,
      reasonText: "",
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    openReason(text) {
        this.reasonText = text;
        this.showReason = true;
    },
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      try {
        let res = await this.$Api(queryWithdrawDetails, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          list.forEach((i) => {
            i.isFollow = true;
          });
          if (type === "refresh") {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    // 上啦加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 复制链接
    copyText(text) {
      this.$copyText(text).then(
        (e) => {
          Toast("复制成功");
        },
        (e) => {
          Toast("复制失败");
        }
      );
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList("refresh");
    },
    handWithdrawalType(type) {
      switch (type) {
        case "usdt":
          return "USDT提现";
        case "bankcard":
          return "银行卡提现";
        case "alipay":
          return "支付宝提现";
        default:
          return "未知";
      }
    },
    handleOrderSta(val) {
      switch (val) {
        case 1:
          return "未审核";
        case 2:
          return "审核通过，转账中";
        case 3:
          return "已拒绝";
        case 4:
          return "未知错误";
        case 5:
          return "提现成功";
        case 6:
          return "提现失败";
        default:
          return "未知";
      }
    },
    handleColor(sta) {
      if (sta == 1 || sta == 2) {
        return "color: #47d441";
      } else if (sta == 3 || sta == 4 || sta == 6) {
        return "color: #d44155";
      } else {
        return "color: #45e7de";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.withdrawalDetails {
  height: 100%;
  color: #000;
  background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 18px;
      color: #000;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .main {
    height: calc(100% - 45px);
    overflow-y: auto;
    padding: 12px 16px 0;
    box-sizing: border-box;
    .listItem {
      height: 86px;
      border-radius: 8px;
      background: #fff;
      overflow: hidden;
      margin-bottom: 12px;
      padding: 12px;
      box-sizing: border-box;
      // font-size: 15px;
      .amount {
        font-size: 18px;
        margin-top: 18px;
        font-weight: 600;
      }
      .orderNo {
        color: #333;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4px;
        .copyBtn {
          width: 48px;
          height: 20px;
          border-radius: 20px;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          background: #94d6da;
        }
      }
      .orderSta {
        margin-bottom: 4px;
        color: #666;
        font-size: 12px;
        display: flex;
        align-items: center;
        .reasonBtn {
          color: #ff8080;
          margin-left: 12px;
        }
      }
      .orderDate {
        color: #999999;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .amountText {
          color: #ff8f4c;
        }
        // margin: 6px 0 12px;
      }
    }
  }
  .popupBox {
    width: 300px;
    border-radius: 10px;
    .popupTitle {
      height: 69px;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid rgba(33, 33, 33, 0.1);
    }
    .popupContent {
        padding: 14px 22px 20px;
        box-sizing: border-box;
        font-size: 14px;
        text-align: center;
    }
    .confirm {
        width: 196px;
        height: 38px;
        border-radius: 38px;
        font-size: 14px;
        margin: 0 auto 27px;
        color: #fff;
        background: #94D6DA;
        display: flex;
        align-items: center;
        justify-content: center;
    }
  }
  /deep/ .van-popup {
    border-radius: 10px;
    background: linear-gradient(180deg, #e5feff 0%, #fff 100%);
    // background-color: rgba(0, 0, 0, 0) !important;
  }
}
</style>
